:root {
  --textPrimary: #020415;
  --textSecondary: #7b889a;

  --backgroundPrimary: #ffffff;
  --backgroundSecondary: #f0f4f8;

  --action: #ff6f00;
  --actionDarker: #d95e00;

  --accent: #195fc7;
  --accentDarker: #134795;

  --accent2: #ff615f;
  --accent2Darker: #ff0b07;

  --titleFontFamily: Figtree, sans-serif;
  --baseFontFamily: Montserrat, sans-serif;
  --monospaceFontFamily: 'Anonymous Pro', monospace;

  --scrollbar: rgba(128, 128, 128, 0.25);

  --border: #dce0e4;
}
