.taskode {
  &-cookies {
    &-modal {
      position: fixed;
      bottom: 0;
      z-index: 2;
      width: 100%;
      max-width: 1240px;
      display: block;
      margin: 0 auto;

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        z-index: -1;
        width: 100vw;
        height: 100%;
        margin-left: -50vw;
        background-color: var(--backgroundSecondary);
        box-shadow: 0 0 32px rgba(37, 40, 42, 0.2);
      }
    }

    &-main {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 16px;
      width: 100%;
      padding: 16px;
    }

    &-icon {
      display: none;

      @media (min-width: 720px) {
        display: block;
      }
    }

    &-customize {
      display: none;
      align-items: center;
      gap: 16px;
      width: 100%;
      padding: 24px 16px;

      &-checkboxes {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        gap: 16px;

        label {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
    }

    &-icon {
      flex: 0 0 auto;
      width: 24px;
      height: 24px;
    }

    &-text {
      flex: 1 1 400px;
    }

    &-buttons {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      gap: 8px;

      #cookies-customize,
      #cookies-reject {
        font-weight: 400;
        color: var(--textPrimary);
      }
    }
  }
}
