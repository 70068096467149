@import './_theme.scss';
@import './_fonts.scss';
@import './_cookies.scss';

:root {
  --headerHeight: 64px;
}

* {
  box-sizing: border-box;
}

html {
  all: initial;
  color: var(--textPrimary);
  font-family: var(--baseFontFamily);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--backgroundPrimary);
}

body {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
}

*:focus {
  outline: none;
}

a {
  color: var(--accent);
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar);
}

* {
  scrollbar-color: var(--scrollbar) transparent;
  scrollbar-width: thin;
}

h1,
h2 {
  margin: 0;
  font-family: var(--titleFontFamily);
  font-weight: 500;

  strong {
    font-weight: inherit;
    color: var(--accent);
  }
}

h1 {
  font-size: 64px;
}

h2 {
  font-size: 48px;
}

p {
  margin: 32px 0;
}

ul {
  margin: 32px 0;
  padding: 0 0 0 32px;
}

li {
  position: relative;
  margin: 24px 0;
  list-style: none;

  &::before {
    content: '';
    position: absolute;
    left: -32px;
    top: -4px;
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78466 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.488 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999' stroke='%2327AE60' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M22 4L12 14.01L9 11.01' stroke='%2327AE60' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  }
}

input,
button {
  font-family: inherit;
}

.taskode {
  &-root {
    display: flex;
    flex-direction: column;
    gap: 48px;
    min-height: 100vh;

    @media (min-width: 720px) {
      gap: 96px;
    }
  }

  &-header {
    flex: 0 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    height: var(--headerHeight);
    margin: 0 16px;

    &-buttons {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    &-button-collab {
      display: none;

      @media (min-width: 720px) {
        display: block;
      }
    }

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 100vw;
      height: 1px;
      margin-left: -50vw;
      background-color: var(--border);
    }
  }

  &-logo {
    display: block;
    height: 24px;
  }

  &-button {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 10px 12px;
    border: 0;
    border-radius: 4px;
    color: var(--accentDarker);
    font-weight: 600;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    cursor: pointer;
    transition: color 0.2s, background-color 0.2s, transform 0.1s;

    &:active {
      transform: scale(0.96);
    }

    &-action,
    &-primary,
    &-primary2 {
      color: var(--backgroundPrimary);
    }

    &-action {
      background-color: var(--action);

      &:active,
      &:hover {
        background-color: var(--actionDarker);
      }
    }

    &-primary {
      background-color: var(--accent);

      &:active,
      &:hover {
        background-color: var(--accentDarker);
      }
    }

    &-primary2 {
      background-color: var(--accent2);

      &:active,
      &:hover {
        background-color: var(--accent2Darker);
      }
    }

    &-large {
      gap: 12px;
      padding: 24px 32px;
      border-radius: 8px;
      font-size: 16px;
    }

    &-arrow::after {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-arrow-right'%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3Cpolyline points='12 5 19 12 12 19'%3E%3C/polyline%3E%3C/svg%3E");
    }
  }

  &-content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    gap: 48px;
    margin: 0 16px;

    @media (min-width: 720px) {
      gap: 96px;
    }

    &-block {
      &-text {
        flex: 1 1 auto;
      }

      &-image {
        display: block;
        flex: 0 0 auto;
        margin: 32px 0 0;
        max-width: 100%;

        @media (min-width: 960px) {
          margin: 0;
        }
      }

      &-link {
        display: inline-flex;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        font-weight: 600;

        &::after {
          content: '';
          display: block;
          width: 24px;
          height: 24px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23195fc7' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-arrow-right'%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3Cpolyline points='12 5 19 12 12 19'%3E%3C/polyline%3E%3C/svg%3E");
        }
      }
    }
  }

  &-footer {
    position: relative;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 32px;
    padding: 16px;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--textPrimary);

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      z-index: -1;
      width: 100vw;
      height: 100%;
      margin-left: -50vw;
      background-color: var(--backgroundSecondary);
    }

    &-links {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 24px;

      a {
        text-decoration: none;
        color: inherit;
      }
    }

    &-copyright {
    }
  }
}

#dashboard-link {
  display: none;
}
